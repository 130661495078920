<template>
  <div class="com-coundown">
    <div v-html="cpd_runTimeHTML">
      <!--<b>0</b><b>1</b>-->
      <!--<i>:</i>-->
      <!--<b>0</b><b>1</b>-->
      <!--<i>:</i>-->
      <!--<b>0</b><b>1</b>-->
      <!--<i>:</i>-->
      <!--<b>0</b><b>1</b>-->
    </div>
  </div>
</template>

<script>
const secondMilliseconds = 1000
const minuteMilliseconds = 60 * 1000
const hourMilliseconds = 60 * minuteMilliseconds
const dayMilliseconds = 24 * hourMilliseconds
const msArr = [dayMilliseconds, hourMilliseconds, minuteMilliseconds, secondMilliseconds]

export default {
  name: "ComCoundown",
  props: {
    status: {
      type: [Number, Boolean],
      default: 0
    },
    targetTime: {
      type: [Number, String],
      default: Date.now()
    }
  },
  data () {
    return {
      runTime: 0
    }
  },
  computed: {
    cpd_runTime () {
      const arr = []
      msArr.reduce((result, m) => {
        arr.push(result < m ? 0 : parseInt(result / m))
        return result % m
      }, this.runTime)
      return arr
    },
    cpd_runTimeHTML () {
      return '<b>'
        + this.cpd_runTime.map(t => {
          return ((t < 10 ? '0' : '') + t).split('').join('</b><b>')
        }).join('</b><i>:</i><b>')
        + '</b>'
    }
  },
  watch: {
    status (newVal) {
      newVal ? this.run() : this.stop()
    },
    targetTime () {
      this.run()
    }
  },
  deactivated () {
    // console.log()
    this.clear('deactivated')
  },
  destroyed () {
    this.clear()
  },
  mounted () {
    this.run()
  },
  methods: {
    run () {
      if (!this.status) return
      const now = Date.now()
      // 倒计时
      if (this.status < 0) {
        let runTime = this.targetTime - now
        this.runTime = runTime < 0 ? 0 : runTime
        if (this.runTime) {
          this.runTimeoutId = setTimeout(this.run, 1000)
        }
        // 正计时
      } else {
        let runTime = now - this.targetTime
        this.runTime = runTime < 0 ? 0 : runTime
        this.runTimeoutId = setTimeout(this.run, 1000)
      }
    },
    stop () {
      clearTimeout(this.runTimeoutId)
      this.$emit('onStop')
      delete this.runTimeoutId
    },
    clear () {
      this.stop()
      this.runTime = 0
    }
  }
}
</script>

<style lang="less">
.com-coundown {
  display: flex;
  text-align: center;
  width: 100%;
  height: 100%;

  & > div {
    display: flex;
    flex: 1;
    height: 100%;
    border-bottom: solid 1px #fff;

    & > * {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #fff;
      color: #fff;
      font-size: 42px;
      font-family: ChalkboardSEBold;
    }

    & > b {
      &:first-of-type {
        border-left: none;
      }
      &:last-of-type {
        border-right: none;
      }
    }
    & > i {
      font-size: 46px;
      font-family: Arial;
      font-style: normal;
    }
  }
}
</style>
